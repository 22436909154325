(function($) {
    var $fn = $(".part_item_filter_section");

    if($fn.length) {
        $fn.each(function () {
            var $this = $(this);
            $(window).on("load resize",function () {
                if($(window).width() > 960) {
                    $this.addClass("mod--active");
                }
                else {
                    $this.removeClass("mod--active");
                }
            });
            $this.on("click",".wrp_header",function () {
                $this.find(".wrp_content").stop().slideToggle(function () {
                    $this.toggleClass("mod--active");
                });
            })
        })
    }
})(jQuery);