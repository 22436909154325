(function($){
    var $fn = $(".comp_products_slider");

    if($fn.length) {
        var $slider = $fn.find(".wrp_slider");

        if(!$slider.children().length){
            $fn.remove();
            return;
        }

        var $autoplay = $slider.data("autoplay") > 0,
            $autoplay_delay = $autoplay ? $slider.data("autoplay") : 0,
            $arrows = $fn.find(".wrp_arrows"),
            $slick_next = $arrows.find("[data-slick-next]"),
            $slick_prev = $arrows.find("[data-slick-prev]");

        $slider.slick({
            infinite: true,
            speed: 500,
            fade: false,
            swipeToSlide: true,
            slidesToShow: 6,
            cssEase: 'linear',
            autoplay: $autoplay,
            autoplaySpeed: $autoplay_delay,
            arrows: true,
            pauseOnHover: false,
            dots: false,
            prevArrow: $slick_prev,
            nextArrow: $slick_next,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 5
                    }
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 960,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
})(jQuery);