(function($) {
    var reInitGCaptcha;

    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LeKN-0UAAAAABu656_VHm-UEsEGNP5C9IA94IS1").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LeKN-0UAAAAABu656_VHm-UEsEGNP5C9IA94IS1', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
        setInterval(function(){reInitGCaptcha();}, 150000);
    }


    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $(document).on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie"))
    {
        $.getScript($cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }
    
    $(document).on('change', '[data-cat-filter]', function(){
        var formData = $('#category-filter').serialize();

        $.ajax({
            method: 'post',
            data: formData,
            cache: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function(){
                    sr.sync();
                });
            }
        });
    });
    
    $(document).on('click', 'a.ajax', function (e) {
        e.preventDefault();
        var $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });
    
    $(document).on('click', '[data-basket-remove]', function (e) {
        e.preventDefault();
        var key = $(this).closest('[data-key]').data('key');

        $.ajax({
            method: 'post',
            data: {do: 'removeProduct', key: key},
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    $(document).on('change', '[data-basket-count]', function () {
        var key = $(this).closest('[data-key]').data('key');
        var val = $(this).val();

        $.ajax({
            method: 'post',
            data: {do: 'countProduct', key: key, value: val},
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    // AJAX FORM //
    $(document).on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        var $frm = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            success: function (payload) {
                fn_ajaxHandler(payload);
            },
            dataType: "json"
        });
    });

    // registrace
    var antispamRegistration = function () {
        if ($('#reg_form').length) {
            setTimeout(function () {
                $('#reg_form').append('<input type="hidden" class="hidden" name="antispam" value="antispam" />');
            }, 3000);
        }
    };

    if ($('#reg_form').length) {
        antispamRegistration();
    }

    // kontakt form
    var antispamContactForm = function () {
        if ($('#snippet-contact').length) {
            setTimeout(function () {
                $('#snippet-contact').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };

    if ($('#snippet-contact').length) {
        antispamContactForm();
    }

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.getScript($cdnjs.lui_cookieconsent);
        }
    })();

})(jQuery);