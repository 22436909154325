(function($){
    var $fn = $(".comp_product_detail");

    if($fn.length){
        var $gallery = $fn.find(".wrp_gallery .elm_image");
        if($gallery.length) {
            $.getScript("https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.0/js/lightgallery-all.min.js").done(function () {
                var $gallery_data = $fn.find("[data-gallery]").data("gallery");
                $gallery.each(function ($index) {
                    var $this = $(this);
                    $this.on("click",function () {
                        $this.lightGallery({
                            dynamic: true,
                            thumbnails: true,
                            counter: true,
                            download: true,
                            closable: true,
                            zoom: false,
                            dynamicEl: $gallery_data,
                            index: $index
                        })
                    })
                })
            });
        }
    }
})(jQuery);