(function($) {
    var $timeout,
        $fn = $(".part_ui_option");

    $fn.on("click","[aria-expanded]",function(e){
        var $el = $(this);
        e.preventDefault();
        e.stopPropagation();
        clearTimeout($timeout);

        $.fn.close = function() {
            $(this).removeClass("mod--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            $timeout = setTimeout(function(){
                $el.attr("aria-expanded","false");
            },300);
        };
        $.fn.open = function() {
            $(this).addClass("mod--toggled").attr("aria-expanded","true").next("[aria-hidden]").removeAttr("aria-hidden");
        };

        $("html").one("click", function() {
            $el.close();
        });
        if ($el.hasClass("mod--toggled")) {
            $el.close();
        } else {
            $el.open();
        }
    });
})(jQuery);