(function($){
    var $fn = $(".comp_visual");

    if($fn.length) {
        var $slider = $fn.find("[data-visual-slider]"),
            $slider_arrows = $slider.next(".wrp_arrows");

        if ($slider.children().length > 1) {
            var $autoplay = $slider.data("autoplay") > 0,
                $autoplay_delay = $autoplay ? $slider.data("autoplay") : 0,
                $slider_caption = $fn.find("[data-visual-caption]"),
                $slick_next = $slider_arrows.find("[data-slick-next]"),
                $slick_prev = $slider_arrows.find("[data-slick-prev]");

            $slider.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: $autoplay,
                autoplaySpeed: $autoplay_delay,
                arrows: true,
                pauseOnHover: false,
                asNavFor: $slider_caption,
                dots: false,
                prevArrow: $slick_prev,
                nextArrow: $slick_next
            });

            $slider_caption.slick({
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: $autoplay,
                autoplaySpeed: $autoplay_delay,
                arrows: false,
                pauseOnHover: false,
                asNavFor: $slider,
                dots: false
            })
        }
        else {
            $slider_arrows.remove();
        }
    }
})(jQuery);